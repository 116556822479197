import { convertToQueryString, upload } from '../http';
import { ParsedResponse } from '../pipes';
import { replaySocket } from '../socket';
import { worker } from '../workers/worker-setup';
import { BaseService } from './base.service';
import { SingleDoc, UserDto } from 'local-types';
import { firstValueFrom } from 'rxjs';

export class UserService extends BaseService<UserDto> {
  path = ({
    id = '',
    isUpload = false,
    query,
  }: {
    id?: string | number;
    isUpload?: boolean;
    query?: Record<string, string | number | boolean>;
  } = {}) =>
    `${this.base()}/users/${id}${isUpload ? '/upload' : ''}?${convertToQueryString(query)}`;

  async post<K = UserDto, T = Partial<UserDto>>(path: string, body: T, headers: HeadersInit = {}) {
    return worker.post(path, body, headers) as Promise<ParsedResponse<SingleDoc<K>>>;
  }

  async login(body: { token: string }) {
    return worker.post(`${this.base()}/users/login`, body);
  }

  async logout() {
    return worker.post(`${this.base()}/users/logout`, {});
  }

  async current() {
    return worker.get(`${this.base()}/users/current`) as Promise<
      ParsedResponse<SingleDoc<UserDto>>
    >;
  }

  async upload(path: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    await firstValueFrom(replaySocket);
    return upload<SingleDoc<UserDto>>(path, formData);
  }
}

export default new UserService();
