import { parseResponse } from './pipes';
import { firstValueFrom } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';

export function convertToQueryString(params: Record<string, string | boolean | number> = {}) {
  return Object.keys(params)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key] ?? '')}`;
    })
    .join('&');
}

export function find<T>(path: string, headersInit?: HeadersInit) {
  const headers = new Headers(headersInit);

  return firstValueFrom(
    fromFetch(path, {
      headers,
      method: 'GET',
    }).pipe(parseResponse<T>())
  );
}

export function get<T>(path: string, headersInit?: HeadersInit) {
  const headers = new Headers(headersInit);

  return firstValueFrom(
    fromFetch(path, {
      headers,
      method: 'GET',
    }).pipe(parseResponse<T>())
  );
}

export function upload<T>(path: string, body: FormData, headersInit: HeadersInit = {}) {
  const headers = new Headers(headersInit);

  return firstValueFrom(
    fromFetch(path, {
      headers,
      method: 'POST',
      body,
    }).pipe(parseResponse<T>())
  );
}

export function post<T>(path: string, body: T, headersInit?: HeadersInit) {
  const headers = new Headers(headersInit);
  headers.append('Content-Type', 'application/json');

  return firstValueFrom(
    fromFetch(path, {
      headers,
      method: 'POST',
      body: JSON.stringify(body),
    }).pipe(parseResponse<T>())
  );
}

export function patch<T>(path: string, body: T, headersInit?: HeadersInit) {
  const headers = new Headers(headersInit);
  headers.append('Content-Type', 'application/json');

  return firstValueFrom(
    fromFetch(path, {
      headers,
      method: 'PATCH',
      body: JSON.stringify(body),
    }).pipe(parseResponse<T>())
  );
}

export function destroy<T>(path: string, headersInit?: HeadersInit) {
  const headers = new Headers(headersInit);

  return firstValueFrom(
    fromFetch(path, {
      headers,
      method: 'DELETE',
    }).pipe(parseResponse<T>())
  );
}
