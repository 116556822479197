import { DEBOUNCE_DURATION } from './constants';
import { debounceTime, exhaustMap } from 'rxjs';

export interface ParsedResponse<T> {
  status: number;
  data: T | null;
}

export const parseResponse = <T>() =>
  exhaustMap(async (response: Response) => {
    try {
      let data = null;
      switch (response.status) {
        case 500:
          break;
        default:
          data = (await response.json()) as T;
      }

      return {
        status: response.status,
        data,
      } as ParsedResponse<T>;
    } catch (error) {
      return {
        status: response.status,
        data: null,
      };
    }
  });

export const debounceXHR = <T>() => debounceTime<T>(DEBOUNCE_DURATION);
