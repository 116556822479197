import { DEBOUNCE_DURATION } from '../constants';
import { errorListener } from '../helpers/error-boundary';
import { ParsedResponse } from '../pipes';
import { replaySocket } from '../socket';
import { worker } from '../workers/worker-setup';
import { ListDoc, SingleDoc } from 'local-types';
import { Subject, debounceTime, firstValueFrom } from 'rxjs';

export class BaseService<T> {
  base = () => '/api/v1';

  xhrPipe = new Subject<() => Promise<any>>();

  constructor() {
    this.xhrPipe.pipe(debounceTime(DEBOUNCE_DURATION)).subscribe((func) => {
      func();
    });
  }

  async find(path: string, headers: HeadersInit = {}) {
    const response = (await worker.find(path, headers)) as unknown as ParsedResponse<ListDoc<T>>;
    parseResponseStatus(response.status);
    return response;
  }
  async get(path: string, headers: HeadersInit = {}) {
    const response = (await worker.get(path, headers)) as ParsedResponse<SingleDoc<T>>;
    parseResponseStatus(response.status);
    return response;
  }
  async post(path: string, body: Partial<T>, headers: HeadersInit = {}) {
    await firstValueFrom(replaySocket);
    const response = (await worker.post(path, body, headers)) as unknown as ParsedResponse<
      SingleDoc<T>
    >;
    parseResponseStatus(response.status);
    return response;
  }
  async patch(path: string, body: Partial<T>, headers: HeadersInit = {}) {
    await firstValueFrom(replaySocket);
    const response = (await worker.patch(path, body, headers)) as unknown as ParsedResponse<
      SingleDoc<T>
    >;
    parseResponseStatus(response.status);
    return response;
  }
  async destroy(path: string, headers: HeadersInit = {}) {
    await firstValueFrom(replaySocket);
    const response = (await worker.destroy(path, headers)) as ParsedResponse<boolean>;
    parseResponseStatus(response.status);
    return response;
  }
}

function parseResponseStatus(status: number) {
  switch (status) {
    case 500:
      errorListener.next(new Error('Request Failed'));
  }
}
