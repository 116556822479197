import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  connectAuthEmulator,
  getAuth,
  GoogleAuthProvider,
} from 'firebase/auth';

const app = initializeApp({
  apiKey: 'AIzaSyC2bi4JPOGr6Zvw7UF8EAJosnj_paG4IAw',
  authDomain: 'game-grid-a25e6.firebaseapp.com',
  projectId: 'game-grid-a25e6',
  messagingSenderId: '518113444866',
  appId: '1:518113444866:web:327ef607699016d9da531b',
  measurementId: 'G-KFEDYZZB35',
});

const googleProvider = new GoogleAuthProvider();
const auth = getAuth(app);
auth.setPersistence(browserLocalPersistence);

/* local-code */
if (location.href.includes('localhost')) {
  connectAuthEmulator(auth, 'http://localhost:9099', {
    disableWarnings: true,
  });
}
/* end-local-code */

export { auth, googleProvider };
