import { cn } from '../../helpers/css';
import { motion } from 'framer-motion';
import { forwardRef } from 'preact/compat';
import * as React from 'react';

const Skeleton = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn('animate-pulse rounded-md bg-muted', className)} {...props} />
    );
  }
);

const MotionSkeleton = motion.create(Skeleton);

export { MotionSkeleton, Skeleton };
