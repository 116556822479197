import { ToastAction } from '../components/ui/toast';
import { toast } from '../components/ui/use-toast';
import { Component } from 'preact';
import { Subject } from 'rxjs';

export const errorListener = new Subject<Error>();

errorListener.subscribe((error) => {
  toast({
    title: 'Error',
    description: error.message,
    action: <ToastAction altText={error.message}>Clear</ToastAction>,
  });
});

export class ErrorBoundary extends Component {
  state = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  clear() {
    this.setState({ error: null });
  }

  componentDidCatch = (error: Error) => {
    this.setState({ error });
    toast({
      title: 'Error',
      description: error.message,
      action: (
        <ToastAction altText={error.message} onClick={this.clear}>
          Clear
        </ToastAction>
      ),
    });
  };

  render() {
    return this.props.children;
  }
}
