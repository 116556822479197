import { UserDto } from 'local-types';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

const BUFFER_SIZE = 1;

export const SIDE_BAR_STATE_KEY = 'SIDE_BAR_STATE';
export const SHARE_BOARD_ACTION_KEY = 'SHARE_BOARD_ACTION';
export const BOARD_ACTION_TOOL_KEY = 'BOARD_ACTION_TOOL';

const userDataListener = new BehaviorSubject<UserDto | null>(null);
const urlLocationListener = new ReplaySubject<string>(BUFFER_SIZE);
const isCallingWebService = new BehaviorSubject<boolean>(false);
const sideBarListener = new BehaviorSubject<'open' | 'closed'>(
  (localStorage.getItem(SIDE_BAR_STATE_KEY) as 'open' | 'closed') || 'open'
);
const characterMoveListener = new Subject<{
  x: number;
  y: number;
  id: number;
}>();

export enum CharacterClickActionType {
  Bind = 'bind',
  CharacterSettings = 'characterSettings',
  BackgroundSettings = 'backgroundSettings',
}
const boardAssetDeleteListener = new Subject<number>();

export {
  boardAssetDeleteListener,
  characterMoveListener,
  isCallingWebService,
  sideBarListener,
  urlLocationListener,
  userDataListener,
};
