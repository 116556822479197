import userService from './api/user.service';
import Header from './components/header';
import { Toaster } from './components/ui/toaster';
import { TooltipProvider } from './components/ui/tooltip';
import { auth } from './firebase';
import { ErrorBoundary } from './helpers/error-boundary';
import Loading from './helpers/loading';
import { useObservable } from './helpers/observable-hook';
import { urlLocationListener, userDataListener } from './helpers/subjects';
import { sendSocketMessageListener, showLogin } from './socket';
import './styles/app.scss';
import './styles/global.css';
import { onAuthStateChanged } from 'firebase/auth';
import { AnimatePresence } from 'framer-motion';
import { UserDto } from 'local-types';
import { render } from 'preact';
import Router, { Route, RouterOnChangeArgs, route } from 'preact-router';
import { Suspense, lazy, useEffect } from 'preact/compat';

const Landing = lazy(() => import('./pages/landing/landing'));
const SignUp = lazy(() => import('./pages/signup/signup'));
const Create = lazy(() => import('./pages/signup/create'));
const Home = lazy(() => import('./pages/home/home'));
const Games = lazy(() => import('./pages/games/games'));
const NewGame = lazy(() => import('./pages/games/create/create'));
const SetupBoard = lazy(() => import('./pages/games/create/setup-board'));
const GameGrid = lazy(() => import('./pages/game-grid/game-grid'));
const VersionList = lazy(() => import('./pages/character-sheets/versions/versions'));
const GamePage = lazy(() => import('./pages/games/game'));
const GameSheet = lazy(() => import('./pages/game-grid/sheet/sheet'));
const Profile = lazy(() => import('./pages/profile/profile'));
const SheetEditor = lazy(() => import('./pages/character-sheets/editor/editor'));
const SheetList = lazy(() => import('./pages/character-sheets/list/list'));
const Preview = lazy(() => import('./pages/character-sheets/editor/preview/index'));
const Notebook = lazy(() => import('./pages/game-grid/notebook'));

const container = document.querySelector('#body') as HTMLElement;
let currentUrl = '';

function syncHeight() {
  document.documentElement.style.setProperty('height', `${window.innerHeight}px`);
}

function preventDefault(e: Event) {
  e.preventDefault();
  e.stopImmediatePropagation();
}

function getCurrentUser() {
  userService
    .current()
    .then(({ data, status }) => {
      if (!data || (status > 200 && status < 500)) {
        showLogin.next(undefined);
        return;
      }

      const { entity } = data;

      if (entity.id) {
        sendSocketMessageListener.next({
          type: 'init',
        });
        userDataListener.next(entity);
        return;
      }

      if (entity.googleAuthId) {
        route(`/sign_up/${entity.googleAuthId}`);
      }
    })
    .catch(console.log);
}

function App() {
  const dbUser = useObservable(userDataListener);

  useEffect(() => {
    getCurrentUser();

    const s = onAuthStateChanged(auth, async (authState) => {
      if (authState) {
        const token = await authState.getIdToken(true);
        await userService.login({ token });

        getCurrentUser();
      } else {
        userDataListener.next(null);
      }
    });

    return () => {
      s();
    };
  }, []);

  useEffect(() => {
    syncHeight();
    window.addEventListener('resize', syncHeight, {
      passive: true,
    });
    document.addEventListener('touchmove', preventDefault, { passive: false });

    return () => {
      window.removeEventListener('resize', syncHeight);
      document.removeEventListener('touchmove', preventDefault);
    };
  }, []);

  return (
    <ErrorBoundary>
      <TooltipProvider delayDuration={200}>
        <Header />
        <section id="app">
          <AnimatePresence mode="popLayout">
            <Suspense fallback={<Loading />}>
              <Router onChange={(e) => handleRouteChange(e, dbUser)}>
                <Landing path="/" />
                <Route path="/sign_up/:id" component={SignUp} />
                <Route path="/create" component={Create} />
                {dbUser && <Route path="/:rest*" component={ProtectedRoutes} />}
              </Router>
            </Suspense>
          </AnimatePresence>
        </section>
        <Toaster />
      </TooltipProvider>
    </ErrorBoundary>
  );
}

function ProtectedRoutes() {
  return (
    <Router>
      <Route path="/home" component={Home} />
      <Route path="/games" component={Games} />
      <Route path="/games/new" component={NewGame} />
      <Route path="/games/setup_board/:id" component={SetupBoard} />
      <Route path="/games/:id" component={GamePage} />
      <Route path="/profile/:id" component={Profile} />
      <Route path="/games/:id/grid" component={GameGrid} />
      <Route path="/games/:id/notebook/:notebookId" component={Notebook} />
      <Route path="/games/:id/notebook/:notebookId/:pageId" component={Notebook} />
      <Route path="/games/:id/grid/:pane?" component={GameGrid} />
      <Route path="/games/:id/grid/:pane/:boardId" component={GameGrid} />
      <Route path="/games/:id/grid/:pane/:boardId/:tab" component={GameGrid} />
      <Route path="/games/:id/sheets/:characterId" component={GameSheet} />
      <Route path="/games/:id/sheets/:characterId/:tab" component={GameSheet} />
      <Route path="/character-sheets" component={SheetList} />
      <Route path="/character-sheets/sheet-editor/:id" component={VersionList} />
      <Route path="/character-sheets/sheet-editor" component={SheetEditor} />
      <Route path="/character-sheets/sheet-editor/:id/:version" component={SheetEditor} />
      <Route path="/character-sheets/sheet-editor/:id/:version/:tab" component={SheetEditor} />
      <Route path="/character-sheets/preview/:id/:version" component={Preview} />
      <Route path="/character-sheets/preview/:id/:version/:tab" component={Preview} />
    </Router>
  );
}

function handleRouteChange(e: RouterOnChangeArgs, dbUser?: UserDto | null) {
  currentUrl = e.url;
  let dispatchUrl = true;

  if (!dbUser && !currentUrl.startsWith('/sign_up/')) {
    dispatchUrl = false;
  }

  if (dispatchUrl) {
    urlLocationListener.next(currentUrl);
  }
}

render(<App />, container);
