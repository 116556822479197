/* eslint-disable no-redeclare */
import { useEffect, useMemo, useState } from 'preact/hooks';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

function useBehaviorSubject<T>(subject: BehaviorSubject<T>) {
  const [state, setState] = useState<T>(subject.getValue());

  useEffect(() => {
    const s = subject.subscribe((value) => {
      setState(value);
    });

    return () => s.unsubscribe();
  }, [subject]);

  return state;
}

function useObservable<T>(observable: Observable<T>, defaultValue: T): T;
function useObservable<T>(observable: Observable<T>, defaultValue?: T): T | undefined;
function useObservable<T>(observable: Observable<T>, defaultValue?: T) {
  const [state, setState] = useState(defaultValue);

  useEffect(() => {
    const s = observable.subscribe((value) => {
      setState(value);
    });

    return () => s.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
}

export function useSubject<T>() {
  return useMemo(() => new Subject<T>(), []);
}

export { useBehaviorSubject, useObservable };
