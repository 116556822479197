import { Link } from '../ui/link';

const links = [
  {
    key: 'home',
    href: '/home',
    title: 'Home',
  },
  {
    key: 'games',
    href: '/games',
    title: 'Games',
  },
  {
    key: 'sheets',
    href: '/character-sheets',
    title: 'Character Sheets',
  },
];

export function HeaderNavLinks() {
  return (
    <nav className="flex gap-x-2">
      {links.map((link) => (
        <Link className="p-2" key={link.key} href={link.href}>
          {link.title}
        </Link>
      ))}
    </nav>
  );
}
