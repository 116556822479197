import { cn } from '../../helpers/css';
import { cva, type VariantProps } from 'class-variance-authority';
import { motion } from 'framer-motion';
import Match from 'preact-router/match';
import { HTMLAttributes, forwardRef } from 'preact/compat';

const linkVariants = cva(
  'inline-flex rounded text-white no-underline transition-colors hover:bg-accent/80',
  {
    variants: {
      variant: {
        default: '',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        nav: 'h-full w-full items-center justify-center p-1 transition-all duration-300 hover:scale-105',
      },
      defaultVariants: {
        variant: 'default',
      },
    },
  }
);

export const Link = forwardRef<
  HTMLAnchorElement,
  HTMLAttributes<HTMLAnchorElement> &
    VariantProps<typeof linkVariants> & { activeClass?: string; strict?: boolean }
>(
  (
    {
      className,
      children,
      href = '',
      activeClass = 'bg-accent shadow-inner shadow-accent-foreground/10',
      variant,
      strict,
      ...props
    },
    ref
  ) => {
    return (
      <Match path={href.toString()}>
        {(m: { matches: boolean; url?: string; path: string }) => {
          const eq = strict ? m.url === href.toString() : m.url?.includes(href.toString());

          return (
            <a
              className={cn(linkVariants({ variant, className }), (m.matches || eq) && activeClass)}
              href={href}
              ref={ref}
              {...props}
            >
              {children}
            </a>
          );
        }}
      </Match>
    );
  }
);

export const MotionLink = motion.create(Link);
