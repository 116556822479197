import { auth } from '../firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

export class AuthenticationService {
  login(provider: GoogleAuthProvider) {
    return signInWithPopup(auth, provider);
  }

  logout() {
    return auth.signOut();
  }
}

export default new AuthenticationService();
