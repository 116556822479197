const BOX_SIZE = 60;
const DRAW_DISTANCE_UNIT = 2;
const DRAW_DISTANCE = DRAW_DISTANCE_UNIT * BOX_SIZE;
const MAX_ZOOM_IN = 1.4;
const MAX_ZOOM_OUT = 0.4;
const FOG_ALPHA = 0.5;
const DEBOUNCE_DURATION = 300;

export {
  BOX_SIZE,
  DRAW_DISTANCE,
  DEBOUNCE_DURATION,
  MAX_ZOOM_IN,
  MAX_ZOOM_OUT,
  DRAW_DISTANCE_UNIT,
  FOG_ALPHA,
};
