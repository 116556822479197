import * as Root from '../ui/tooltip';
import { useToast } from '../ui/use-toast';
import { Placement } from '@floating-ui/dom';
import { ComponentChild, VNode } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

interface TooltipProps {
  id: string;
  children: ComponentChild;
  trigger: VNode;
  copyText?: string;
  type?: 'hover' | 'click';
  allowedPlacements?: Placement[];
}
export function Tooltip({
  id,
  children,
  trigger,
  type = 'hover',
  copyText,
  allowedPlacements = ['top', 'bottom', 'left', 'right'],
}: TooltipProps) {
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const { toast } = useToast();

  function openMenu() {
    setOpen(true);
  }

  function closeMenu() {
    setOpen(false);
  }

  useEffect(() => {
    if (!triggerRef?.current) {
      return;
    }
    const t = triggerRef.current;

    function copy(e: Event) {
      e.preventDefault();
      e.stopPropagation();
      if (!copyText) {
        return;
      }
      navigator.clipboard.writeText(copyText);
      toast({
        title: 'Copied!',
        description: <span>{copyText}</span>,
      });
    }

    if (copyText) {
      t.addEventListener('click', copy);
    }
    if (type === 'hover') {
      t.addEventListener('pointerenter', openMenu);
      t.addEventListener('pointerleave', closeMenu);
    } else {
      t.addEventListener('click', openMenu);
    }

    t.addEventListener('focusin', openMenu);
    t.addEventListener('focusout', closeMenu);

    return () => {
      if (copyText) {
        t.removeEventListener('click', copy);
      }
      if (type === 'hover') {
        t.removeEventListener('pointerenter', openMenu);
        t.removeEventListener('pointerleave', closeMenu);
      } else {
        t.removeEventListener('click', openMenu);
      }

      t.removeEventListener('focusin', openMenu);
      t.removeEventListener('focusout', closeMenu);
    };
  }, [triggerRef, copyText, type, toast]);

  useEffect(() => {
    if (!open || type === 'hover') {
      return;
    }

    function click(e: Event) {
      const el = document.querySelector(`#${id}`);
      const target = e.target as HTMLElement | null;
      if (!target || !el) {
        return;
      }

      const eventIsChildOfList = el.contains(target);
      if (!eventIsChildOfList) {
        closeMenu();
      }
    }

    requestAnimationFrame(() => {
      document.addEventListener('click', click);
    });

    return () => {
      document.removeEventListener('click', click);
    };
  }, [id, open, type]);

  return (
    <Root.Tooltip open={open} key={id}>
      <Root.TooltipTrigger ref={triggerRef} asChild>
        {trigger}
      </Root.TooltipTrigger>
      <Root.TooltipContent side={allowedPlacements[0]}>{children}</Root.TooltipContent>
    </Root.Tooltip>
  );
}
