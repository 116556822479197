import { generateId } from './helpers/generateId';
import { useObservable } from './helpers/observable-hook';
import { ReceiveAction, SendAction, SocketInit } from './workers/socket-worker';
import { useEffect } from 'preact/hooks';
import { catchError, filter, firstValueFrom, fromEvent, ReplaySubject, skip, Subject } from 'rxjs';

const worker = new Worker(new URL('workers/socket-worker.ts', import.meta.url), {
  type: 'module',
});

export const sendSocketMessageListener = new ReplaySubject<SocketInit | SendAction>();

sendSocketMessageListener.subscribe((data) => {
  worker.postMessage(data);
});

const socket = fromEvent<MessageEvent<ReceiveAction<unknown>>>(worker, 'message').pipe(
  catchError((err, caught) => {
    console.log(err);
    return caught;
  })
);

export const replaySocket = new ReplaySubject<MessageEvent<ReceiveAction<unknown>>>(1);
export const socketReconnectionEvent = new ReplaySubject<string>(1);
export const showLogin = new Subject();

socket
  .pipe(filter(({ data }) => data.type === 'pong' || data.type === 'error'))
  .subscribe((event) => replaySocket.next(event));

replaySocket.pipe(skip(1)).subscribe(({ data }) => {
  if (data.type === 'pong') {
    socketReconnectionEvent.next(generateId());
  }
});

socket.pipe(filter(({ data }) => data.type === 'logout')).subscribe(() => {
  showLogin.next(undefined);
});

fromEvent(document, 'visibilitychange').subscribe(async () => {
  if (document.visibilityState === 'visible') {
    const socketStatus = await firstValueFrom(replaySocket);

    switch (socketStatus.data.type) {
      case 'error': {
        if (!socketStatus.data.retrying) {
          sendSocketMessageListener.next({
            type: 're-init',
          });

          location.reload();

          const newSocketStatus = await firstValueFrom(replaySocket.pipe(skip(1)));
          if (newSocketStatus.data.type === 'pong') {
            socketReconnectionEvent.next(generateId());
          }
        }
      }
    }
  }
});

export function useSocketEvent<T>(
  path: string,
  room: string,
  func: (data: ReceiveAction<T>) => void
) {
  const reconnect = useObservable(socketReconnectionEvent);

  useEffect(() => {
    sendSocketMessageListener.next({
      type: 'join',
      path,
      room,
    });

    const subscription = socket
      .pipe(
        filter(({ data }) => {
          switch (data.type) {
            case 'add':
            case 'update':
            case 'action':
            case 'destroy': {
              return data.room === `${path}/${room}`;
            }
          }

          return false;
        })
      )
      .subscribe(({ data }) => {
        func(data as ReceiveAction<T>);
      });

    return () => {
      subscription.unsubscribe();
      sendSocketMessageListener.next({
        type: 'leave',
        path,
        room,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, room, reconnect]);
}
