import userService from '../../api/user.service';
import { cn } from '../../helpers/css';
import { useObservable } from '../../helpers/observable-hook';
import { userDataListener } from '../../helpers/subjects';
import authenticationService from '../../services/authentication-service';
import { replaySocket, showLogin } from '../../socket';
import { ReceiveAction } from '../../workers/socket-worker';
import { Tooltip } from '../tooltip/tooltip';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Link } from '../ui/link';
import { HeaderNavLinks } from './nav-links';
import { Placement } from '@floating-ui/dom';
import { useSignal } from '@preact/signals';
import { CheckCircle, HelpCircle, XCircle } from 'lucide-react';
import { Fragment } from 'preact';
import { route } from 'preact-router';
import { Suspense, lazy } from 'preact/compat';
import { useEffect, useState } from 'preact/hooks';

const allowedPlacements: Placement[] = ['left'];

function getSocketStatus(data?: ReceiveAction<unknown>) {
  switch (data?.type) {
    case 'pong':
      return <CheckCircle className="h-4 w-4" />;
    case 'error':
      return <XCircle className="h-4 w-4" />;
  }

  return <HelpCircle className="h-4 w-4" />;
}

function getSocketConnectionInformation(data?: ReceiveAction<unknown>) {
  switch (data?.type) {
    case 'pong':
      return 'Connection Successful';
    case 'error':
      return 'Connection Error';
  }

  return 'Initiating Socket Connection';
}

function getSocketConnectionClass(data?: ReceiveAction<unknown>) {
  switch (data?.type) {
    case 'pong':
      return cn('text-green-500');
    case 'error':
      return cn('text-destructive');
  }

  return cn('text-orange-400');
}

const Login = lazy(() => import('../../pages/login/login'));

export default function Header() {
  const user = useObservable(userDataListener);
  const socket = useObservable(replaySocket);
  const mountLogin = useSignal(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const s = showLogin.subscribe(() => {
      setShow(true);
      mountLogin.value = true;
    });

    return () => {
      s.unsubscribe();
    };
  }, [mountLogin]);

  function handleLogin() {
    mountLogin.value = true;
    setShow(true);
  }

  function handleLogout() {
    userService
      .logout()
      .then(() => {
        userDataListener.next(null);
        return authenticationService.logout();
      })
      .then(() => route('/'));
  }

  return (
    <header>
      <Suspense fallback={<Fragment />}>
        {mountLogin.value && <Login show={show} setShow={setShow} />}
      </Suspense>

      <section className="grid grid-cols-[1fr_auto] p-2">
        <HeaderNavLinks />

        <aside>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className="flex gap-2">
                <Tooltip
                  id="socket-status"
                  allowedPlacements={allowedPlacements}
                  trigger={
                    <span className={getSocketConnectionClass(socket?.data)}>
                      {getSocketStatus(socket?.data)}
                    </span>
                  }
                >
                  {getSocketConnectionInformation(socket?.data)}
                </Tooltip>

                <span>{user ? user.username : 'Account'}</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              {user ? (
                <DropdownMenuItem asChild>
                  <Link className="mb-1 w-full" href={`/profile/${user?.id}`}>
                    Profile
                  </Link>
                </DropdownMenuItem>
              ) : (
                <DropdownMenuItem asChild>
                  <Link className="mb-1 w-full" href={`/create`}>
                    Create Account
                  </Link>
                </DropdownMenuItem>
              )}
              <DropdownMenuItem onClick={user ? handleLogout : handleLogin}>
                {user ? 'Log Out' : 'Log In'}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </aside>
      </section>
    </header>
  );
}
